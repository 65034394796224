const AboutComponent = () => {
    return (
        <div className="container-xxl py-5">
            <div className="container px-lg-5">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="section-title position-relative mb-4 pb-2">
                            <h6 className="position-relative text-primary ps-4">About Us</h6>
                            <h2 className="mt-2">Our objectives</h2>
                        </div>
                        <p className="mb-4">Looking to optimize your costs on AWS cloud? Iguazú has the perfect tools and processes to ensure that your expenses stay in check. We offer various solutions such as monitoring, data-driven management, alternative AWS instance types, and advanced automation, all of which we have successfully implemented for our customers.</p>
                        <div className="row g-3">
                            <div className="col-sm-4">
                                <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Capacity Planning and Instance Right-Sizing</h6>
                                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Cost Monitoring and Analytics</h6>
                            </div>
                            <div className="col-sm-4">
                                <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Automation for Cost-Effective Scalability</h6>
                                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Reserved Instance Purchasing</h6>
                            </div>
                            <div className="col-sm-4">
                                <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>The right use of Spot Instance</h6>
                                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>A cloud environment with the right cost</h6>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <a className="btn btn-primary rounded-pill px-4 me-3" href="">Read More</a>
                            <a className="btn btn-outline-primary btn-square me-3" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-primary btn-square me-3" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-outline-primary btn-square me-3" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="./assets/img/about.jpg" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutComponent;