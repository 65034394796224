import HomeComponent from "./components/home/home.component";
import ContactPage from "./pages/contact";
import ServicesPage from "./pages/services";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomeComponent />} />
        <Route path="services" element={<ServicesPage />} />
        <Route path="contact" element={<ContactPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
