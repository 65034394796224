const PortfolioComponent = () => {
    return (
        <div className="container-xxl py-5">
            <div className="container px-lg-5">
                <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                    <h2 className="mt-2">Highlights</h2>
                </div>
                <div className="row g-4 portfolio-container">
                    <div className="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.1s">
                        <div className="position-relative rounded overflow-hidden">
                            <img className="img-fluid w-100" src="./assets/img/portfolio-1.jpg" alt="" style={{ filter: "blur(3px)" }} />
                            <div className="portfolio-overlay">
                                <p>
                                We use Docker to simplify the process of containerizing and distributing applications. Our automation tools help with software development, and we assist in running applications on a managed cluster. By using Docker containerization, we can deploy your software quickly and efficiently. 
                                </p>
                            </div>
                            <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                                <h1 className="text-dark">Containerization</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.3s">
                        <div className="position-relative rounded overflow-hidden">
                            <img className="img-fluid w-100" src="./assets/img/portfolio-2.jpg" style={{ filter: "blur(3px)" }} alt=""/>
                            <div className="portfolio-overlay">
                                <p>
                                DevOps involves adopting practices that allow developer, operations, and other stakeholder teams to work towards the same goal, rather than working in siloed departments. We promote better collaboration across teams by using open-source tools and a combination of technologies that increase the frequency of new software releases.
                                </p>
                            </div>
                            <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                                <h1 className="text-dark">Continuous Delivery</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.6s">
                        <div className="position-relative rounded overflow-hidden">
                            <img className="img-fluid w-100" src="./assets/img/portfolio-3.jpg" alt="" style={{ filter: "blur(3px)" }} />
                            <div className="portfolio-overlay">
                                <p>
                                Automated configuration management is key to a successful DevOps operation. With Iguazú, you can automate your entire software delivery cycle, from core infrastructure to applications, and free up time to work on projects that deliver more value to your business. We have extensive experience working with open-source configuration management tools such as Chef, Ansible, and Puppet, as well as with serverless and containerization technologies such as Docker, AWS ECS, and Kubernetes.  
                                </p>
                            </div>
                            <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                                <h1>Auto-Config</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.1s">
                        <div className="position-relative rounded overflow-hidden">
                            <img className="img-fluid w-100" src="./assets/img/portfolio-4.jpg" alt="" style={{ filter: "blur(3px)" }} />
                            <div className="portfolio-overlay">
                                <p>
                                We offer around-the-clock coverage and support to ensure that your business is always up and running.
                                </p>
                            </div>
                            <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                                <h1>24x7 Support</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.3s">
                        <div className="position-relative rounded overflow-hidden">
                            <img className="img-fluid w-100" src="./assets/img/portfolio-5.jpg" alt="" style={{ filter: "blur(3px)" }} />
                            <div className="portfolio-overlay">
                                <p>
                                Migrating your applications to the cloud can be a complex and time-consuming process without the right tools and expertise. Our team has extensive experience managing migration projects for both startups and enterprises. We work with you at every stage of the migration process to ensure that the results align with your infrastructure and business needs.
                                </p>
                            </div>
                            <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                                <h1>Cloud Migration</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.6s">
                        <div className="position-relative rounded overflow-hidden">
                            <img className="img-fluid w-100" src="./assets/img/portfolio-6.jpg" alt="" style={{ filter: "blur(3px)" }} />
                            <div className="portfolio-overlay">
                                <p>
                                Enterprises often wonder whether security can keep up with the speed of DevOps teams. With Iguazú, we can help you formalize infrastructure design and automate security controls so that security is built into every aspect of the IT management process. Our proprietary automation and configuration management scripts continuously enforce your desired security or compliance configurations, which reduces the risk of manual error or configuration drift.
                                </p>
                            </div>
                            <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                                <h1>Security Automation</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioComponent;