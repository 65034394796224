import AboutComponent from "../about/about.component";
import FooterComponent from "../footer/footer.component";
import FullSearchComponent from "../fullSearch/fullSearch.component";
import HeaderComponent from "../header/header.component.";
import HeroComponent from "../hero/hero.component";
import NewsletterComponent from "../newsletter/newsletter.component";
import PortfolioComponent from "../portoflio/portfolio.component";
import ServicesComponent from "../servicios/services.component";
import TeamComponent from "../team/team.component";
import TestimonialsComponent from "../testimonials/testimonials.component";

const HomeComponent = () => {
    return (
        <div className="container-xxl bg-white p-0">
            <HeaderComponent></HeaderComponent>
            <HeroComponent></HeroComponent>
            <FullSearchComponent></FullSearchComponent>
            <AboutComponent></AboutComponent>
            <NewsletterComponent></NewsletterComponent>
            <ServicesComponent></ServicesComponent>
            <PortfolioComponent></PortfolioComponent>
            <TeamComponent></TeamComponent>
            <FooterComponent></FooterComponent>
        </div>
    )
}

export default HomeComponent;