import React from "react";
import HeaderComponent from "../components/header/header.component.";
import FooterComponent from "../components/footer/footer.component";

const migrationText =
  "At Iguazú, we have extensive hands-on experience in cloud migration and architecture. Our clients range from small startups to large enterprises. We understand that cloud adoption can present significant technical and operational challenges for organizations. As your partner, we will work closely with you throughout the migration process to accelerate your IT transformation and ensure that our solutions align with your business needs. Our cloud migration process includes a comprehensive assessment of your organization's needs and challenges, designing a cloud architecture, creating a migration plan to AWS cloud, building a secured multi-tier cloud environment, and migrating workloads. We tailor our approach to meet your specific organizational, operational, and development requirements. Our goal is to provide you with a reliable, scalable, and secure cloud environment that can be enhanced as per your business needs. Our end goal is to provide you with a fully configured and automated cloud environment, including your application code. We will provide a thorough handover to you and explain the usage of the artifacts. We also offer day-two services, such as 24/7 DevOps NOC and ongoing support services, which many of our clients choose to use.";


const devopsText =
    "We streamline and automate infrastructure deployment processes to help enterprises achieve faster innovation, better operational efficiency, improved deployment quality, and tangible returns on investment. \nIguazú provides strong source control and elasticity in cloud computing, as well as automated monitoring. Our cohesive teams have shared objectives, follow lean principles, and continuously deliver value. Frequent testing reduces the chance of failures and rollbacks, enabling us to QA our work regularly. We use a fine-grained service architecture and have a fast mean-time to recovery. We construct cloud infrastructure that is self-healing and modular, and supports your DevOps practices."

    function ServicesPage() {
  return (
    <>
      <HeaderComponent></HeaderComponent>
      <div className="container-xxl py-5 bg-primary hero-header mb-5">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-12 text-center">
              <h1 className="text-white animated zoomIn">Service</h1>
              <hr className="bg-white mx-auto mt-0" style={{ width: "90px" }} />
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      Pages
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Service
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div
            className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h6 className="position-relative d-inline text-primary ps-4">
              Our Services
            </h6>
            <h2 className="mt-2">What Solutions We Provide</h2>
          </div>
          <div className="row g-4">
            <ul
              class="nav nav-tabs justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="migration-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#migration"
                  type="button"
                  role="tab"
                  aria-controls="migration"
                  aria-selected="true"
                >
                  Cloud Migration
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="devops-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#devops"
                  type="button"
                  role="tab"
                  aria-controls="devops"
                  aria-selected="false"
                >
                  DevOps & CI/CD
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="support-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#support"
                  type="button"
                  role="tab"
                  aria-controls="support"
                  aria-selected="false"
                >
                  24/7/365 Support
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="managed-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#managed"
                  type="button"
                  role="tab"
                  aria-controls="managed"
                  aria-selected="false"
                >
                  Managed Services
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="training-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#training"
                  type="button"
                  role="tab"
                  aria-controls="training"
                  aria-selected="false"
                >
                  Training & Education
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="cloud-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#cloud"
                  type="button"
                  role="tab"
                  aria-controls="cloud"
                  aria-selected="false"
                >
                  Private/Public/Hybrid Cloud
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="migration"
                role="tabpanel"
                aria-labelledby="migration-tab"
              >
                <p class="parrafo animated zoomIn">{migrationText}</p>
              </div>
              <div
                class="tab-pane fade"
                id="devops"
                role="tabpanel"
                aria-labelledby="devops-tab"
              >
                <p class="parrafo animated zoomIn">{devopsText}</p>                
              </div>
              <div
                class="tab-pane fade"
                id="support"
                role="tabpanel"
                aria-labelledby="support-tab"
              >
                <p class="parrafo animated zoomIn">Is your DevOps team overwhelmed with work? Are you not meeting customer SLAs? Iguazú has a team of certified AWS Engineers who can handle day-to-day operations for your cloud environment, working with your in-house engineers as an extension of your DevOps and IT team.</p>
                <p class="parrafo animated zoomIn">Our valued toolset:</p>
                <ul>
                    <li>24x7 DevOps NOC</li>
                    <li>Incident Response</li>
                    <li>Proactive Monitoring and Escalation</li>
                    <li>OS Maintenance and Patching</li>
                    <li>Monitor different aspects of the environment and workloads</li>
                    <li>Security - cloud activities, malicious or unauthorized behaviors</li>
                    <li>Preventative maintenance and incident response</li>
                    <li>Reliability - identifying bottlenecks and weak spots to improve performance and reliability</li>
                    <li>Continuously monitor application performance to identify issues before your customers do</li>
                    <li>Billing - continuously monitor the billing aspects of your workload</li>
                </ul>
                <p class="parrafo animated zoomIn"><b>Outcome:</b> Let us take care of your day-to-day operations so that your DevOps team can focus on driving new business for your organization.</p>    
              </div>
              <div
                class="tab-pane fade"
                id="managed"
                role="tabpanel"
                aria-labelledby="managed-tab"
              >
                <p class="parrafo animated zoomIn">Iguazú offers managed services to companies seeking a long-term partnership, providing comprehensive DevOps support tailored to their specific business needs at competitive prices. We draw on our vast knowledge and experience gained from serving hundreds of satisfied clients.</p>
                <p class="parrafo animated zoomIn">Our guarantees include:</p>
                <ul>
                    <li>Complete coverage of all DevOps needs</li>
                    <li>Ongoing collaboration</li>
                    <li>Analysis of current and future business needs by our solution architects</li>
                    <li>Development of a long-term strategy and regular updates</li>
                    <li>Dedicated DevOps engineers with expertise in relevant DevOps and AWS Cloud technologies</li>
                    <li>Implementation of best practices for long-term strategy while supporting your current business needs</li>
                </ul>
              </div>
              <div
                class="tab-pane fade"
                id="training"
                role="tabpanel"
                aria-labelledby="training-tab"
              >
                <p class="parrafo animated zoomIn">Training text</p>    
              </div>
              <div
                class="tab-pane fade"
                id="cloud"
                role="tabpanel"
                aria-labelledby="cloud-tab"
              >
                <p class="parrafo animated zoomIn">Cloud Text</p> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent></FooterComponent>
    </>
  );
}

export default ServicesPage;
