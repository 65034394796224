const HeroComponent = () => {
    return (
        <div className="container-xxl py-5 bg-primary hero-header mb-5">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-lg-6 text-center text-lg-start">
              <h1 className="text-white mb-4 animated zoomIn">
                DevSecOps as a Service
              </h1>
              <p className="text-white pb-3 animated zoomIn parrafo">
                We assist in transforming your software delivery process to
                achieve greater efficiency, accelerate product release cycles,
                and improve the quality of your software builds by identifying
                potential issues early on. We design infrastructures on public
                clouds like AWS or private clouds such as VMware, Hyper-V, or
                any vendor of your choice, ensuring operational excellence,
                security, reliability, performance efficiency, and cost
                optimization. We construct cloud infrastructure that is
                self-healing and modular, and supports your DevOps practices.
              </p>
              <a
                href=""
                className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft"
              >
                Free Quote
              </a>
              <a
                href=""
                className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
              >
                Contact Us
              </a>
            </div>
            <div className="col-lg-6 text-center text-lg-start">
              <img className="img-fluid" src="img/hero.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    )
}

export default HeroComponent;